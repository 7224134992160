// Here you can add other styles

.mr-3 {
  margin-right: 0.75rem;
}

.logout-link {
  display: flex;
  color: inherit;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.arrow-sort {
  margin-left: 10px;
  opacity: 0;
}

.arrow-sort.active {
  opacity: 1;
}

.revert {
  transform: rotate(180deg);
}

.pointer {
  cursor: pointer;
}

.chevron-down {
  transform: rotate(90deg);
}

.table-row-error {
  position: relative;
}

.tooltip-error {
  position: absolute;
  display: block;
  color: #e55353 !important;
  box-shadow: 0 0 0 0.25rem rgb(229 83 83 / 25%);
  border: 1px solid #e55353;
  border-radius: 4px;
  background-color: #fff;
  width: 300px;
  padding: 5px 10px;
  top: 55px;
  left: 40px;
}

th {
  white-space: nowrap;
}

.pagination {
  justify-content: center;
}

.shortLink {
  max-width: 200px;
  text-decoration: none;
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & a {
    color: inherit;
    text-decoration: none;
    transition: .3s;
  }

  & a:hover {
    color: var(--cui-link-hover-color);
  }
}

.amount-table-cell {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.date-table-cell {
  white-space: nowrap;
}

.actions-block {

  display: flex;
  flex-direction: column;

  align-items: stretch;
  gap: 5px;
  padding: 10px;
}

.load-overlay {

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255,255,255, .5);
}